<template>
  <div class="page">
    <el-card class="searchContainer">
      <el-form inline>
        <el-form-item label="用户昵称">
          <el-input
            v-model="filter.memberName"
            placeholder="请输入用户昵称"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="filter.memberId" placeholder="请输入用户ID" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="filter.orderId" placeholder="请输入订单编号" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>

        <el-form-item label="订单状态">
          <el-select class="searchInput" v-model="filter.status" placeholder="请选择订单状态">
            <el-option label="未核销" value="1"> </el-option>
            <el-option label="已完成" value="2"> </el-option>
            <el-option label="已过期" value="3"> </el-option>
            <el-option label="退款中" value="4"> </el-option>
            <el-option label="已退款" value="5"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="导出状态">
          <el-select class="searchInput" v-model="filter.orderExportStatus" placeholder="请选择导出状态">
            <el-option label="未导出" value="0"> </el-option>
            <el-option label="已导出" value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="init">重置</el-button>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="tableContainer">
      <div class="header" slot="header">
        <span class="title">订单列表</span>

        <el-dropdown @command="handleManage($event)" style="float: right">
          <el-button size="mini" style="float: right" type="success"
            >导出<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">未导出订单</el-dropdown-item>
            <el-dropdown-item command="2">已退款订单</el-dropdown-item>
            <el-dropdown-item command="3">已完成订单</el-dropdown-item>
            <el-dropdown-item command="4">全部订单</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table :data="list" fit center ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="orderId" width="70" label="主订单号" align="center"> </el-table-column>

        <el-table-column prop="orderCheckCodeId" label="子订单号" align="center"> </el-table-column>
        <el-table-column prop="memberName" label="用户昵称" align="center"> </el-table-column>
        <el-table-column prop="memberId" label="用户ID" align="center"> </el-table-column>
        <el-table-column align="center" prop="status" label="订单状态">
          <template slot-scope="scope">
            {{ scope.row.orderStatus | codelessOrderStatus }}
          </template>
        </el-table-column>
        |

        <el-table-column prop="orderExportStatus" label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.orderExportStatus === 0">未导出</div>
            <div v-else-if="scope.row.orderExportStatus === 1">已导出</div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="操作" width="200px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderStatus === 1"
              @click="handleSingleComplete(scope.row)"
              type="text"
              size="mini"
              >完成</el-button
            >
            <el-button v-if="scope.row.orderStatus === 1" @click="handleSingleRefund(scope.row)" type="text" size="mini"
              >退款</el-button
            >
            <el-button @click="handleToDetail(scope.row)" type="text" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="Selection">
          <el-button type="info" @click="toggleSelection(list)">反选</el-button>
          <el-button type="primary" @click="batchRefund">批量退款</el-button>
          <el-button type="primary" @click="batchComplete">批量完成</el-button>
        </div>
        <el-pagination
          background
          :layout="pageLayout"
          :total="page.total"
          :page-sizes="sizeList"
          :page-size="page.size"
          :current-page="page.page"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        ></el-pagination>
        <div></div>
      </div>

      <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="params.showComplete" width="350px">
        是否{{ params.single === true ? '' : '批量' }}完成订单
        <span slot="footer" class="dialog-footer">
          <el-button @click="params.showComplete = false">取 消</el-button>
          <el-button type="primary" @click="confirmBatchComplete">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog :close-on-click-modal="false" :title="params.title" :visible.sync="params.showBatchRefund">
        选择理由
        <el-select style="margin-right: 20px" v-model="params.refundReasonId" placeholder="请选择">
          <el-option v-for="item in rejectReasonList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="params.showBatchRefund = false">取 消</el-button>
          <el-button type="primary" @click="confirmRefund">发起退款</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tableMixin from '@/mixin/table'

export default {
  mixins: [tableMixin],
  data: () => ({
    filter: {
      checkStatus: '',
      memberId: '',
      orderExportStatus: '',
      orderId: ''
    },
    params: {
      orderCheckCodeIds: [],
      showComplete: false,
      showBatchRefund: false,
      skuId: '',
      single: false,
      refundReasonId: '',
      refundReason: '',
      title: ''
    },
    rejectReasonList: [],
    download: { status: ' ', orderExportStatus: '' }
  }),
  created() {
    this.getRefundReasonList()
  },

  methods: {
    ///退款原因列表
    getRefundReasonList() {
      this.$api.base
        .base('refundReasonList', {
          params: {
            page: 1,
            size: 100
          }
        })
        .then(res => {
          this.rejectReasonList = res.list
          this.params.refundReasonId = res.list[0].id
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    batchRefund() {
      if (this.params.orderCheckCodeIds.length === 0) {
        this.$message.error('请先选择要操作的订单')
        return
      }
      this.params.title = ' 批量退款订单'

      this.params.showBatchRefund = true
    },
    batchComplete() {
      if (this.params.orderCheckCodeIds.length === 0) {
        this.$message.error('请先选择要操作的订单')
        return
      }

      this.params.single = false
      this.params.showComplete = true
    },
    confirmBatchComplete() {
      this.$http
        .put('/boom-center-order-service/sysAdmin/orderAttachInformation/update/status', {
          businessId: this.list[0].businessId,
          orderCheckCodeIds: this.params.orderCheckCodeIds
        })
        .then(res => {
          this.params.showComplete = false

          if (res.failIdList.length !== 0) {
            this.$message.error(`本次操作成功${res.successIdList.length}个，失败${res.failIdList.length}个`)
          } else {
            this.$message.success(`操作成功${res.successIdList.length}个`)
          }
          this.handleSearch()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    handleSingleRefund(val) {
      this.params.title = '单个退款订单'
      this.params.showBatchRefund = true
      this.handleSelectionChange([val])
    },
    handleSingleComplete(val) {
      this.params.single = true
      this.params.showComplete = true
      this.handleSelectionChange([val])
    },
    handleSelectionChange(val) {
      this.params.orderCheckCodeIds.splice(0)
      this.params.orderCheckCodeIds = val.map(i => {
        return i.orderCheckCodeId
      })
    },

    // 确认退款
    confirmRefund() {
      this.rejectReasonList.forEach(item => {
        if (item.id == this.params.refundReasonId) {
          this.params.refundReason = item.name
        }
      })

      console.log(this.params.refundReason)
      console.log(this.params.refundReasonId)
      this.$http
        .post('/boom-center-order-service/sysAdmin/refund/apply/batch', {
          orderCheckCodeIdList: this.params.orderCheckCodeIds,
          refundReason: this.params.refundReason,
          refundReasonId: this.params.refundReasonId
        })
        .then(res => {
          this.params.showBatchRefund = false
          this.handleSearch()
          this.$message.success('操作成功,请到申请退款列表查看')
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },

    handleManage(command) {
      if (command === '1') {
        this.download = {
          orderExportStatus: 0
        }
      } else if (command === '2') {
        this.download = {
          refundStatus: 2
        }
      } else if (command === '3') {
        this.download = {
          checkStatus: 2
        }
      } else if (command === '4') {
        this.download = {}
      }
      this.downLoadExcel()
    },

    downLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .post('/boom-center-order-service/sysAdmin/orderAttachInformation/exportExcel', {
          skuId: this.$route.query.id,
          ...this.download
        })
        .then(res => {
          this.$store
            .dispatch('upload/getFileUrlWidthSignature', res)
            .then(response => {
              window.open(response.replace('http:', '').replace('https:', ''))
            })
            .catch(err => {
              this.$message.error(err.msg)
            })
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    getList() {
      if (this.filter.status == '4') {
        this.filter.checkStatus = ''
        this.filter.refundStatus = '1'
      } else if (this.filter.status == '5') {
        this.filter.checkStatus = ''
        this.filter.refundStatus = '2'
      } else {
        this.filter.checkStatus = this.filter.status
        this.filter.refundStatus = ''
      }

      let { status, ...params } = this.filter
      return this.$http.get('/boom-center-order-service/sysAdmin/orderAttachInformation/select/codelessCommodity', {
        params: {
          ...params,
          ...this.page,
          skuId: this.$route.query.id
        }
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleReset() {
      this.filter = {
        status: '',
        memberId: '',
        orderExportStatus: '',
        orderId: ''
      }
    }, // 查看订单
    handleToDetail(row) {
      this.$router.push({
        path: '/order/orderParticulars',
        query: {
          id: row.orderId,
          memberId: row.memberId,
          type: 'refundDetail',
          productType: 3
        }
      })
    }
  },
  filters: {
    codelessOrderStatus(val) {
      switch (val) {
        case -1:
          return '未支付'
          break
        case 1:
          return '未核销'
          break
        case 2:
          return '已完成'
          break
        case 3:
          return '已过期'
          break
        case 11:
          return '退款中'
          break
        case 12:
          return '已退款'
          break
        default:
          return ''
          break
      }
    },
    checkCodeStatus(val) {
      switch (val) {
        case 1:
          return '未核销'
          break
        case 2:
          return '已核销'
          break
        case 3:
          return '已过期'
          break
        case 11:
          return '退款中'
          break
        case 12:
          return '已退款'
          break
        default:
          return '没有这个状态'
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}

/deep/ .el-table__expand-icon {
  position: relative;
  cursor: pointer;
  color: #666;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
  height: 30px;
  font-size: 20px;
}
.tableContainer .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.miniUrl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 40px;
  img,
  div {
    margin: 10px 0;
  }
}

.skuContainer {
  display: flex;
  .info {
    flex-grow: 1;
    display: flex;
    margin-right: 5px;
  }
  .skuName {
    width: 120px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
